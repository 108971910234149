import Vue from "vue";
import Router from "vue-router";

import Comments from "./views/Comments.vue";
import Pano from "./views/Pano.vue";
import Books from "./views/Books.vue";
import BookDetail from "./views/BookDetail.vue";
import ChangePassword from "./views/ChangePassword.vue";

Vue.use(Router);

export default new Router({
  base: "/",
  routes: [
    {
      path: "/",
      name: "Default",
      component: Comments
    },
    {
      path: "/comments",
      name: "Comments",
      component: Comments
    },
    {
      path: "/pano",
      name: "Pano",
      component: Pano
    },
    {
      path: "/books",
      name: "Books",
      component: Books
    },
    {
      path: "/books/:id",
      name: "BookDetail",
      component: BookDetail
    },
    {
      path: "/changepwd",
      name: "ChangePassword",
      component: ChangePassword
    }
  ]
});

<script>
import { getBook, updateBook, deleteBook } from "@/api";
import ImageUploader from "@/components/ImageUploader";
import Gallery from "vue-gallery";

export default {
  components: { Gallery, ImageUploader },
  data() {
    return {
      book: undefined,
      galleryIndex: undefined
    };
  },
  async created() {
    let { id } = this.$route.params;
    this.book = await getBook(id);
  },
  methods: {
    async removeImage(index) {
      this.book.pages.splice(index, 1);
      await this.saveBook();
    },
    changeName() {
      this.$prompt("请填写图书名称", "修改图书名称", {
        inputValue: this.book.name,
        inputValidator(val) {
          return val ? true : "请填写图书名称";
        }
      }).then(async ({ value }) => {
        this.book.name = value;
        await this.saveBook();
      });
    },
    async saveBook() {
      await updateBook(this.book);
    },
    async uploaded(url) {
      this.book.pages.push(url);
      await this.saveBook();
    },
    deleteBook() {
      this.$confirm("确定要删除该图书吗？", "请确认", {
        type: "warning"
      }).then(async () => {
        await deleteBook(this.book.id);
        this.$router.replace({ name: "Books" });
      });
    }
  }
};
</script>
<template>
  <div v-if="book">
    <el-row type="flex" class="title">
      <h3>
        {{book.name}}
        <el-button type="text" @click="changeName()">修改</el-button>
      </h3>
      <el-button type="danger" @click="deleteBook" size="small">删除图书</el-button>
    </el-row>
    <el-row>
      <el-col :span="6">
        <image-uploader multiple catalog="book" @uploaded="uploaded"></image-uploader>
      </el-col>
    </el-row>
    <br>
    <el-alert title="拖动以调整顺序" type="info" show-icon :closable="false"></el-alert>
    <br>
    <el-row
      type="flex"
      class="pages"
      :gutter="20"
      v-draggable
      :drag-model="book.pages"
      @sorted="saveBook"
    >
      <el-col :span="3" v-for="(page,index) in book.pages" :key="page">
        <div class="page" @click="galleryIndex=index">
          <img :src="page" alt>
          <el-button
            @click.capture.stop="removeImage(index)"
            icon="el-icon-close"
            type="text"
            class="image__delete"
          ></el-button>
        </div>
      </el-col>
    </el-row>
    <gallery :images="book.pages" :index="galleryIndex" @close="galleryIndex = null"></gallery>
  </div>
</template>

<style lang="scss" scoped>
.title {
  justify-content: space-between;
  align-items: center;
}
.pages {
  display: flex;
  flex-wrap: wrap;
}
.page {
  flex-shrink: 0;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0 0 1px #ccc;
  cursor: pointer;

  img {
    width: 100%;
  }
  .image__delete {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ff4949;
    display: block;
    border-radius: 50%;
    border: 1px solid white;
    padding: 5px;
    color: white;
    opacity: 0;
    transition: 0.3s;
    font-size: 10px;
    box-shadow: 0 0 1px #888;
    z-index: 1000;
  }

  &:hover .image__delete {
    opacity: 1;
  }
}
</style>

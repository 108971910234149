<script>
import jsSHA from "jssha";
import { resetAdminPassword } from "@/api";

export default {
  data() {
    return {
      password: {
        newPassword: undefined,
        confirmPassword: undefined
      }
    };
  },
  methods: {
    async submit() {
      let { newPassword, confirmPassword } = this.password;
      if (!newPassword || !confirmPassword) {
        this.$message.error("请填写要修改的密码");
      } else if (newPassword !== confirmPassword) {
        this.$message.error("两次输入的密码不一致");
      } else {
        var shaObj = new jsSHA("SHA-256", "TEXT");
        shaObj.update(newPassword);
        await resetAdminPassword(shaObj.getHash("HEX"));
        this.$message.success("密码修改成功");
        this.password.newPassword = this.password.confirmPassword = undefined;
      }
    }
  }
};
</script>
<template>
  <div>
    <h3>修改密码</h3>
    <el-form label-position="left" label-width="100px">
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          type="password"
          v-model="password.newPassword"
          placeholder="请填写新密码"
          style="width:300px;"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input
          type="password"
          v-model="password.confirmPassword"
          placeholder="请确认新密码"
          style="width:300px;"
        ></el-input>
      </el-form-item>
      <el-button type="primary" @click="submit()">提交修改</el-button>
    </el-form>
  </div>
</template>


<script>
import { getUptoken } from "@/api";

export default {
  data() {
    return {
      upData: null
    };
  },
  props: {
    catalog: {
      type: String,
      required: true
    }
  },
  methods: {
    async resolveKey(file) {
      let ext = file.name.substr(file.name.lastIndexOf(".") + 1);
      let token = await getUptoken(this.catalog, ext);
      this.upData = {
        key: token.key,
        token: token.token
      };
    },

    async uploaded(res) {
      this.$emit("uploaded", res.url);
      this.$message.success("上传成功");
    }
  }
};
</script>

<template>
  <el-upload
    :before-upload="resolveKey"
    action="https://up.qbox.me"
    accept="audio/mpeg"
    :data="upData"
    :on-success="uploaded"
    :show-file-list="false"
  >
    <el-button size="small" type="primary">点击上传</el-button>
  </el-upload>
</template>
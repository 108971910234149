import dragula from "dragula";
import "dragula/dist/dragula.min.css";

const Global = {};

function getDomIndex(child, parent) {
    return Array.prototype.indexOf.call(parent.children, child);
}

export default {
    name: "Draggable",
    bind(el, binding, vnode) {

        let container,
            identifier = parseInt(Math.random() * 100000).toString(),
            model = vnode.data.attrs["drag-model"],
            selector = binding.value;

        if (!Array.isArray(model)) {
            throw new Error("invalid drag model");
        }

        el.dataset.draggable = identifier;

        if (selector) {
            if (typeof (selector) === "string") {
                container = el.querySelector(selector);
            } else if (typeof (selector) === "object") {
                container = selector;
            } else {
                container = el
            }
        } else {
            container = el;
        }

        let drake = dragula([container]);

        let dragIndex;
        drake.on("drag", (element, source) => {
            dragIndex = getDomIndex(element, source);
        });
        drake.on("drop", (element, target) => {
            let dropIndex = getDomIndex(element, target);
            let model = Global[identifier].model;
            model.splice(dropIndex, 0, model.splice(dragIndex, 1)[0]);
            drake.cancel(true);

            const handlers = (vnode.data && vnode.data.on) || (vnode.componentOptions && vnode.componentOptions.listeners);
            if (handlers && handlers.sorted) {
                handlers.sorted.fns();
            }
        });

        Global[identifier] = {
            model,
            drake
        };

    },
    componentUpdated(el, binding, vnode) {
        let identifier = el.dataset.draggable;
        Global[identifier].model = vnode.data.attrs["drag-model"];
    },
    unbind(el) {
        let identifier = el.dataset.draggable;
        Global[identifier].drake.destroy();
        delete Global.identifier;
    }
}
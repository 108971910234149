<script>
import { getUptoken } from "@/api";

export default {
  data() {
    return {
      loading: false,
      upData: null
    };
  },
  props: {
    catalog: {
      type: String,
      required: true
    }
  },
  methods: {
    async resolveKey(file) {
      let ext = file.name.substr(file.name.lastIndexOf(".") + 1);
      let token = await getUptoken(this.catalog, ext);
      this.upData = {
        key: token.key,
        token: token.token
      };
      this.loading = true;
    },
    async uploaded(res) {
      this.$emit("uploaded", res.url);
      this.loading = false;
      this.$message.success("上传成功");
    },
    uploadFailed() {
      this.loading = false;
      this.$message.error("上传失败");
    }
  }
};
</script>

<template>
  <div class="container" v-loading="loading" element-loading-text="拼命加载中">
    <el-upload
      :before-upload="resolveKey"
      action="https://up.qbox.me"
      accept="video/mp4"
      :data="upData"
      :on-success="uploaded"
      :show-file-list="false"
      :on-error="uploadFailed"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传mp4文件</div>
    </el-upload>
  </div>
</template>
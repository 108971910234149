import axios from "axios";
import Vue from "vue";

const req = axios.create({
  timeout: 5000,
  responseType: "json",
  baseURL: "/api"
});

req.interceptors.response.use(
  function(res) {
    return res.data;
  },
  function(err) {
    let res = err.response;

    if (res.status === 401) {
      // window.dispatchEvent(new CustomEvent("unauthorize"));
      // throw new Error("unauthorize");
      window.location = "./login.html";
    } else if (res.data && (res.data.msg || res.data.message)) {
      let msg = res.data.msg || res.data.message;
      Vue.prototype.$message.error(msg);
      throw new Error(msg);
    } else {
      Vue.prototype.$message.error("请求出错");
      throw new Error("请求出错");
    }
  }
);

export async function checkAuth() {
  return await req.get("/check-auth");
}

export async function getComments(params) {
  return await req.get("/comments", { params });
}

export async function updateComments(commentIds, visible) {
  await req.put("/comments", { commentIds, visible });
}

export async function getAllPanos() {
  return await req.get("/panos");
}

export async function updatePano(panoId, { bgm, bgmEnabled }) {
  await req.put("/panos/" + panoId, { bgm, bgmEnabled });
}

export async function getHotspot(id) {
  return await req.get("/hotspots/" + id);
}

export async function getVidespot(name) {
  return await req.get("/videospots/" + name);
}

export async function saveHotspot(hotspot) {
  await req.put("/hotspots/" + hotspot.id, hotspot);
}

export async function saveVideospot(videospot) {
  await req.put("/videospots/" + videospot.name, videospot);
}

export async function getUptoken(catalog, ext) {
  return await req.get("/uptoken", { params: { catalog, ext } });
}

export async function getBooks() {
  return await req.get("/books");
}

export async function getBook(id) {
  return await req.get("/books/" + id);
}

export async function addBook(name) {
  return await req.post("/books", { name });
}

export async function updateBook(book) {
  return await req.put("/books/" + book.id, book);
}

export async function deleteBook(id) {
  return await req.delete("/books/" + id);
}

export async function saveStoreUrl(url) {
  await req.put("/store-url", { url });
}

export async function adminLogin(username, password) {
  await req.post("/admin-login", { username, password });
}

export async function resetAdminPassword(password) {
  await req.post("/admin-reset-password", { password });
}

<script>
import { getAllPanos, updatePano } from "@/api";
import HotspotEditor from "./HotspotEditor";
import VideospotEditor from "./VideospotEditor";
import AudioUploader from "@/components/AudioUploader";

export default {
  components: {
    HotspotEditor,
    VideospotEditor,
    AudioUploader
  },
  async created() {
    this.panos = await getAllPanos();
    this.panoId = this.panos[0].panoId;
  },
  data() {
    const vm = this;
    return {
      panos: [],
      panoId: undefined,
      panoObj: undefined,
      sceneBarVisible: false,
      currentScene: undefined,
      hotspotId: undefined,
      videospotName: undefined,
      hooks: {
        sceneChanged(scene) {
          vm.currentScene = scene;
        },
        showHotspot(id) {
          vm.hotspotId = id;
        },
        showVideospot(name) {
          vm.videospotName = name;
        },
        showBooks() {}
      }
    };
  },
  methods: {
    panoCreated(panoObj) {
      this.panoObj = panoObj;
    },
    changeScene(scene) {
      this.panoObj.call(`loadscene(${scene},null, MERGE, BLEND(0.8))`);
    },
    async bgmUploaded(url) {
      this.currentPano.bgm = url;
      await updatePano(this.panoId, { bgm: url });
      this.$message.success("保存成功");
    },
    async bgmEnableChange() {
      let bgmEnabled = this.currentPano.bgmEnabled;
      await updatePano(this.panoId, { bgmEnabled });
    }
  },
  computed: {
    currentPano() {
      return this.panos.find(p => p.panoId === this.panoId);
    },
    xmlPath() {
      if (this.panoId) {
        return `/api/pano/${this.panoId}.xml`;
      } else {
        return undefined;
      }
    }
  },
  watch: {
    panoId() {
      this.currentScene = this.currentPano.scenes[0].name;
    }
  }
};
</script>
<template>
  <div v-if="this.panoId">
    <h3>全景编辑</h3>
    <el-tabs v-model="panoId">
      <el-tab-pane
        v-for="pano of panos"
        :key="pano.panoId"
        :label="pano.floorNum+'楼'"
        :name="pano.panoId"
      ></el-tab-pane>
    </el-tabs>
    <el-form inline>
      <el-form-item label="展厅">
        <el-select v-model="currentScene" @change="changeScene" style="width:400px;">
          <el-option
            v-for="scene in currentPano.scenes"
            :key="scene.name"
            :value="scene.name"
            :label="scene.title"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="语音导览">
        <div class="bgm">
          <audio :src="currentPano.bgm" controls></audio>
          <audio-uploader catalog="bgm" @uploaded="bgmUploaded"></audio-uploader>
          <el-switch
            active-text="启用语音"
            style="margin-left:20px;"
            v-model="currentPano.bgmEnabled"
            @change="bgmEnableChange"
          ></el-switch>
        </div>
      </el-form-item>
    </el-form>

    <krpano
      :mwheel="true"
      class="pano"
      :xml="xmlPath"
      @panoCreated="panoCreated"
      :hooks="hooks"
      :lazyLoad="false"
      :mobilescale="1"
    ></krpano>
    <hotspot-editor :hotspotId.sync="hotspotId"></hotspot-editor>
    <videospot-editor :videospotName.sync="videospotName"></videospot-editor>
  </div>
</template>
<style lang="scss" scoped>
.pano {
  width: 100%;
  height: 70vh;
}

.bgm {
  display: flex;
  align-items: center;
  audio {
    margin-right: 10px;
  }
}
</style>


import Vue from "vue";
import ElementUI from "element-ui";
import "./index.css";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import VueKrpano from "vue-krpano";
import Cookie from "js-cookie";
import Draggable from "./draggable";

let token = Cookie.get("token");
if (token) {
  Vue.config.productionTip = false;
  Vue.use(ElementUI);
  Vue.use(VueKrpano);
  Vue.directive(Draggable.name, Draggable);
  Vue.filter("qiniu", (url, args) => {
    if (!url.startsWith("http")) {
      url = "https://zhendan-cdn.rebase.com.cn/" + url;
    }

    if (args) {
      url = url + "?imageView2/" + args;
    }

    return url;
  });
  new Vue({
    router,
    render: h => h(App)
  }).$mount("#app");
} else {
  window.location = "/login.html";
}

<script>
import Cookie from "js-cookie";
import { checkAuth } from "./api";

export default {
  async created() {
    await checkAuth();
  },
  methods: {
    logout() {
      Cookie.remove("token");
      window.location = "/login.html";
    }
  }
};
</script>

<template>
  <el-container class="main">
    <el-aside class="aside" style="width:200px;">
      <img class="logo" src="./assets/logo.png" alt>

      <el-menu class="menu" mode="vertical" router>
        <el-menu-item index="/comments">
          <i class="el-icon-edit"></i>
          <span slot="title">评论管理</span>
        </el-menu-item>
        <el-menu-item index="/pano">
          <i class="el-icon-location-outline"></i>
          <span slot="title">全景编辑</span>
        </el-menu-item>
        <el-menu-item index="/books">
          <i class="el-icon-goods"></i>
          <span slot="title">文创图书</span>
        </el-menu-item>
        <el-menu-item index="/changepwd">
          <i class="el-icon-edit"></i>
          <span slot="title">修改密码</span>
        </el-menu-item>
        <el-menu-item index="logout" @click="logout">
          <i class="el-icon-back"></i>
          <span slot="title">退出登录</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main class="body">
      <router-view/>
    </el-main>
  </el-container>
</template>

<style lang="scss">
.main {
  min-height: 100vh;
}
.body {
  padding: 20px 40px;
}
.aside {
  width: 200px;
  border-right: 1px solid #e6e6e6;
}

.logout {
  margin-top: 50px;
  text-align: center;

  button {
    margin: 0 auto;
    color: #888;
  }
}
.logo {
  width: 80%;
  margin: 20px auto;
  display: block;
}

.menu {
  border-right: none;
}
</style>

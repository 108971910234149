<script>
import ImageUploader from "@/components/ImageUploader";
import AudioUploader from "@/components/AudioUploader";
import { getHotspot, saveHotspot } from "@/api";

export default {
  components: {
    ImageUploader,
    AudioUploader
  },
  props: ["hotspotId"],
  data() {
    return {
      hotspot: undefined,
      rules: {
        title: { required: true }
      }
    };
  },
  async created() {},
  methods: {
    imageUploaded(url) {
      this.hotspot.images.push(url);
    },
    audioUploaded(url) {
      this.hotspot.audio = url;
    },
    removeImage(index) {
      this.hotspot.images.splice(index, 1);
    },
    async save() {
      this.$refs.hotspotForm.validate(async valid => {
        if (valid) {
          await saveHotspot(this.hotspot);
          this.$message.success("热点信息已保存");
          this.showHotspotEditor = false;
        } else {
          this.$message.error("请检查填写内容");
        }
      });
    }
  },
  watch: {
    async hotspotId(val) {
      if (val) {
        this.hotspot = await getHotspot(val);
      } else {
        this.hotspot = undefined;
      }
    }
  },
  computed: {
    showHotspotEditor: {
      get() {
        return !!this.hotspotId;
      },
      set() {
        this.$emit("update:hotspotId", undefined);
      }
    }
  }
};
</script>
<template>
  <el-form v-if="hotspot" :model="hotspot" :rules="rules" ref="hotspotForm">
    <el-dialog :visible.sync="showHotspotEditor" title="编辑热点" top="5%">
      <el-form-item label="标题" prop="title">
        <el-input v-model="hotspot.title"></el-input>
      </el-form-item>
      <el-form-item label="正文">
        <el-input v-model="hotspot.text" type="textarea" rows="10"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <image-uploader catalog="hotspot_image" @uploaded="imageUploaded"></image-uploader>
        <div v-draggable :drag-model="hotspot.images">
          <div
            class="image"
            v-for="(img,index) in hotspot.images"
            :key="img"
            :style="{'background-image':`url(${img})`}"
          >
            <el-button
              @click.capture.stop="removeImage(index)"
              icon="el-icon-close"
              type="text"
              class="image__delete"
            ></el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="语音">
        <audio-uploader catalog="hotspot_audio" @uploaded="audioUploaded"></audio-uploader>
        <div v-if="hotspot.audio">
          <audio :src="hotspot.audio" controls></audio>
          <el-button type="text" @click="hotspot.audio=null">清除</el-button>
        </div>
      </el-form-item>
      <el-form-item label="环物链接">
        <el-input v-model="hotspot.vrobj"></el-input>
      </el-form-item>
      <div slot="footer">
        <el-button type="primary" @click="save">保存修改</el-button>
      </div>
    </el-dialog>
  </el-form>
</template>
<style lang="scss" scoped>
.image {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
  margin-right: 20px;
  position: relative;

  .image__delete {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ff4949;
    display: block;
    border-radius: 50%;
    border: 1px solid white;
    padding: 5px;
    color: white;
    opacity: 0;
    transition: 0.3s;
    font-size: 10px;
    box-shadow: 0 0 1px #888;
    z-index: 1000;
  }

  &:hover .image__delete {
    opacity: 1;
  }
}
</style>


<template>
  <div>
    <h3>评论管理</h3>
    <el-row>
      <el-button
        plain
        icon="el-icon-check"
        :disabled="buttonDisabled"
        @click="showComments"
        size="small"
      >评论可见</el-button>
      <el-button
        plain
        icon="el-icon-close"
        :disabled="buttonDisabled"
        @click="hideComments"
        size="small"
      >评论隐藏</el-button>
    </el-row>
    <el-table
      ref="commentsTable"
      :data="commentsData.list"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="id" width="180"></el-table-column>
      <el-table-column prop="text" label="评论" width="480"></el-table-column>
      <el-table-column prop="user.nickname" label="用户名"></el-table-column>
      <el-table-column prop="visible" label="是否可见">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.visible==true" type="success" size="small">可见</el-tag>
          <el-tag v-else type="danger" size="small">隐藏</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      @current-change="changePage"
      :page-count="commentsData.page.total"
    ></el-pagination>
  </div>
</template>

<script>
import { getComments, updateComments } from "@/api";
export default {
  data() {
    return {
      query: { page: 1 },
      commentsData: {
        list: [],
        page: { current: 0, total: 0 }
      },
      multipleSelection: []
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.commentsData = await getComments({
        all: "1",
        page: this.query.page
      });
    },
    async saveData(commentIds, visibility) {
      await updateComments(commentIds, visibility);
      this.$refs.commentsTable.clearSelection();
    },
    changePage(page) {
      this.query.page = page;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //console.log(val);
    },
    hideComments: function() {
      var i;
      //var commentIDs = [];
      for (i of this.multipleSelection) {
        i.visible = false;
        //commentIDs.push(i.id);
      }
      var commentIDs = this.multipleSelection.map(function(obj) {
        return obj.id;
      });
      this.saveData(commentIDs, false);
      this.$message({
        message: "评论已不可见",
        type: "success"
      });
    },
    showComments: function() {
      var i;
      for (i of this.multipleSelection) {
        i.visible = true;
      }
      var commentIDs = this.multipleSelection.map(function(obj) {
        return obj.id;
      });
      this.saveData(commentIDs, true);
      this.$message({
        message: "评论已可见",
        type: "success"
      });
    }
  },
  computed: {
    buttonDisabled() {
      return this.multipleSelection.length == 0;
    }
  }
};
</script>

<style>
</style>
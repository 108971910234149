<script>
import {getUptoken} from "@/api";

export default {
  data() {
    return {
      uploadHost: null,
      upData: null
    };
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    catalog: {
      type: String,
      required: true
    }
  },
  methods: {
    async resolveKey(file) {
      let ext = file.name.substr(file.name.lastIndexOf(".") + 1);
      let token = await getUptoken(this.catalog, ext);
      this.uploadHost = token.bucketHost;
      this.upData = token;
    },

    async uploaded(res) {
      this.$emit("uploaded", this.upData.cdnUrl);
      this.$message.success("图片上传成功");
    }
  }
};
</script>

<template>
  <el-upload
      :multiple="multiple"
      :before-upload="resolveKey"
      :action="uploadHost"
      accept="image/jpeg, image/png, image/gif"
      :data="upData"
      :on-success="uploaded"
  >
    <el-button size="small" type="primary">点击上传</el-button>
    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2mb</div>
  </el-upload>
</template>
<script>
import { getVidespot, saveVideospot } from "@/api";
import VideoUploader from "@/components/VideoUploader";

export default {
  components: {
    VideoUploader
  },
  props: ["videospotName"],
  data() {
    return {
      videospot: undefined
    };
  },
  methods: {
    videoUploaded(url) {
      this.videospot.videoUrl = url;
    },
    async save() {
      await saveVideospot(this.videospot);
      this.$message.success("热点信息已保存");
      this.showVideospotEditor = false;
    }
  },
  watch: {
    async videospotName(val) {
      if (val) {
        this.videospot = await getVidespot(val);
      } else {
        this.videospot = undefined;
      }
    }
  },
  computed: {
    showVideospotEditor: {
      get() {
        return !!this.videospotName;
      },
      set() {
        this.$emit("update:videospotName", undefined);
      }
    }
  }
};
</script>
<template>
  <el-form v-if="videospot" ref="videospotForm">
    <el-dialog :visible.sync="showVideospotEditor" title="编辑视频热点" top="5%">
      <el-form-item label="视频">
        <video-uploader catalog="hotspot_video" @uploaded="videoUploaded"></video-uploader>
        <video style="width:100%" :src="videospot.videoUrl" controls controlsList="nofullscreen nodownload noremoteplayback"></video>
      </el-form-item>
      <div slot="footer">
        <el-button type="primary" @click="save">保存修改</el-button>
      </div>
    </el-dialog>
  </el-form>
</template>


<script>
import { getBooks, addBook, saveStoreUrl } from "@/api";
export default {
  async created() {
    let { books, storeUrl } = await getBooks();
    this.books = books;
    this.storeUrl = storeUrl;
  },
  data() {
    return {
      books: [],
      storeUrl: undefined
    };
  },
  methods: {
    async saveStoreUrl() {
      this.$prompt("请填写商城链接地址", "修改商城链接", {
        inputValue: this.storeUrl,
        inputValidator(val) {
          return val ? true : "请填写商城链接地址";
        }
      }).then(async ({ value }) => {
        this.storeUrl = value;
        await saveStoreUrl(this.storeUrl);
        this.$message.success("保存成功");
      });
    },
    addBook() {
      this.$prompt("请填写图书名", "添加图书", {
        inputValidator(val) {
          return val ? true : "请填写图书名";
        }
      }).then(async ({ value }) => {
        let book = await addBook(value);
        this.$router.push({ name: "BookDetail", params: { id: book.id } });
      });
    }
  }
};
</script>
<template>
  <div>
    <el-row type="flex" style="justify-content:space-between;align-items:center">
      <h3>文创图书</h3>
      <div>
        <el-button type="primary" @click="addBook" size="small">添加图书</el-button>
      </div>
    </el-row>
    <el-form inline label-width="100px" label-position="left">
      <el-form-item label="商城链接">
        <a target="_blank" :href="storeUrl">{{storeUrl}}</a>
        <el-button
          style="margin-left:20px"
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="saveStoreUrl"
        >修改</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="30" type="flex" class="books">
      <el-col :span="4" v-for="book in books" :key="book.id">
        <router-link :to="{name:'BookDetail',params:{id:book.id}}">
          <el-card :body-style="{ padding: '0px' }" class="book">
            <img :src="`books/${book.name}/1.jpg`|qiniu" class="book__image">
            <div class="book__title">{{book.name}}</div>
          </el-card>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.books {
  flex-wrap: wrap;
}
.book {
  margin-bottom: 30px;

  .book__image {
    width: 100%;
    display: block;
  }
  .book__title {
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
